.edit-add-reference-click .add-reference-click button {
  height: 35px;
  width: 150px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  font-size: 13px;
}

.edit-add-reference-click
  .reference-edit-details:where(.css-dev-only-do-not-override-mxhywb).ant-modal
  .ant-modal-close:hover {
  background-color: transparent;
}

.edit-add-reference-click
  .reference-edit-details:where(.css-dev-only-do-not-override-mxhywb).ant-modal
  .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0px 0px !important;
}
.edit-add-reference-click .reference-edit-details .ant-modal-content {
  padding: 0 !important;
}
.edit-add-reference-click .reference-edit-details .ant-modal-close:hover {
  background-color: transparent;
  cursor: pointer;
}

.new-reference-all-section .new-reference-header .reference-cancel-btn-sec {
  position: absolute;
  right: -2.2rem;
  bottom: 29.5rem;
  font-size: 25px !important;
  color: white;
  cursor: pointer;
}

/* .edit-add-reference-click .reference-edit-details .ant-modal-close-x {
  position: absolute;
  top: -40px;
  right: -45px;
  border: 2px solid white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font-size: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-add-reference-click .reference-edit-details .ant-modal-close span {
  line-height: 0px;
} */

.edit-add-reference-click .new-reference-all-section .new-reference-header {
  height: 48px;
  width: 100%;
  background-color: lightgray;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-add-reference-click .new-reference-all-section .new-reference-header p {
  font-size: 14px;
  font-weight: 700;
  margin: 0px;
}

.edit-add-reference-click .all-input-name-section .name-and-title-part {
  display: flex;
  justify-content: space-around;
}

.edit-add-reference-click .name-and-title-part .css-brpa64 {
  display: flex;
  flex-wrap: wrap;
}

.edit-add-reference-click
  .textfield-input-name
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(161, 161, 161, 0.6);
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 0;
  top: -5px;
  transform: translate(14px, 16px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}

.edit-add-reference-click
  .textfield-input-name
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 6.5px 10px;
}

.edit-add-reference-click .textfield-input-name .MuiFormControl-root {
  margin: 0px;
  width: 40%;
  margin: 3% 5% 1% 5%;
}

.edit-add-reference-click .new-reference-all-section .reference-submit-btn {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

.edit-add-reference-click
  .new-reference-all-section
  .reference-submit-btn
  button {
  height: 35px;
  width: 90px;
  border-radius: 5px;
  border: 1px solid gray;
  background-color: white;
  background: transparent;
}

.edit-add-reference-click
  .new-reference-all-section
  .reference-submit-btn
  button:hover {
  background-color: #265f9d;
  color: white;
}

.main-navbar-in-referrals-page .navbar-in-logo-img {
  width: 130px;
  margin-left: 35px;
  /* height: 50px; */
  /* background-color:  aqua; */
  cursor: pointer;
}
@media only screen and (max-width: 425px) {
  .main-navbar-in-referrals-page .navbar-in-logo-img {
    margin-left: 0px;
  }
}
.main-navbar-in-referrals-page .menu-in-header {
  /* width: 100%; */
  /* height: 100%; */
  margin-left: 20%;
  display: flex;
  /* gap: 30px; */
}
/* .refferel-but{
    height: 70px;
} */


.main-navbar-in-referrals-page .navbar-in-profile-vive-but {
    width: 63%;
    margin-bottom: 1rem;
    height: 30px;
    border-radius: 15px;
    background-color: #265F9D;
    font-size: 12px;
    color: white;
    border: none;
    opacity: 1;
    cursor: pointer;

}
.main-navbar-in-referrals-page .candidate-but {
  width: 165px;
  /* text-align: center; */
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: center;
  /* padding-left: 20px; */
}
.main-navbar-in-referrals-page .candidate-but1 {
  width: 165px;
  /* text-align: center; */
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: center;
  background-color: #265f9d;
  color: #ffffff;
  /* padding-left: 20px; */
}
.main-navbar-in-referrals-page .referrals-but1 {
  width: 165px;
  /* text-align: center; */
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: center;
  background-color: #265f9d;
  color: #ffffff;
  /* padding-left: 20px; */
}
.main-navbar-in-referrals-page .requirements-but1 {
  width: 165px;
  /* text-align: center; */
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: center;
  background-color: #265f9d;
  color: #ffffff;
  /* padding-left: 20px; */
}
.main-navbar-in-referrals-page .requirements-but {
  width: 160px;
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: center;
}
.main-navbar-in-referrals-page .referrals-but {
  width: 160px;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
}
.main-navbar-in-referrals-page .candidate-but:hover {
  background-color: #265f9d;
  text-align: center;
  display: flex;
  align-items: center;
  /* padding-left: 30px; */
  color: #ffffff;
  font-size: 17px;
}
.main-navbar-in-referrals-page .requirements-but:hover {
  background-color: #265f9d;
  text-align: center;
  display: flex;
  align-items: center;
  /* padding-left: 30px; */
  color: #ffffff;
  font-size: 17px;
}
.main-navbar-in-referrals-page .referrals-but:hover {
  background-color: #265f9d;
  text-align: center;
  display: flex;
  align-items: center;
  /* padding-left: 30px; */
  color: #ffffff;
  font-size: 17px;
}
.main-navbar-in-referrals-page .navbar-expand-md .navbar-nav {
  margin-right: 3%;
}
.main-navbar-in-referrals-page .mb-3 {
  margin: 0;
}
.main-navbar-in-referrals-page .bg-light {
  background-color: #ffffff !important;
}
.main-navbar-in-referrals-page .profile-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 900px; */
}
.main-navbar-in-referrals-page a {
  text-decoration: none;
  /* width: 150px; */
  /* height: 60px; */
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 16px;
  /* padding: 15px; */
  color: black;
  transition: 0.5s;
  cursor: auto;
}
.main-navbar-in-referrals-page .dropdown-item:hover {
  background-color: transparent;
  /* color: black; */
}

.offcanvas-end{
  width: 90% !important;
}

.main-navbar-in-referrals-page .navbar-toggler{
  border: none;
}

.main-navbar-in-referrals-page .navbar-toggler:focus{
box-shadow: none;
}

.main-navbar-in-referrals-page .dropdown-menu[data-bs-popper] {
    top:180%;
    left: -8rem;
    margin-top: var(--bs-dropdown-spacer);
    border: 2px solid #EBEBEB;
border-radius: 10px;
}
.main-navbar-in-referrals-page .navbar-expand-md .navbar-nav .nav-link{
  cursor: pointer;
}

.main-navbar-in-referrals-page .drop-down{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: max-content;
    width: 210px;
} 



.profile-pic-div{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid black;
}
.main-navbar-in-referrals-page .navbar {
  padding: 0px;
}
.main-navbar-in-referrals-page .container-fluid {
  /* box-shadow: 0px 3px 29px #0000000f; */
  z-index: 5;
  height: 65px;
  top: 0px;
left: 0px;
width: 1920px;
/* height: 106px; */
/* UI Properties */
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 29px #0000000F;
opacity: 1;
}

.main-navbar-in-referrals-page .dropdown-divider {
  display: none;
}

.main-navbar-in-referrals-page .navbar-in-profile-Logout-but {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    /* background-color: #265F9D; */
    font-size: 15px;
    color: black;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
    gap: 5px;
    border: none;
    opacity: 1;
    cursor: pointer;
}

.main-navbar-in-referrals-page .main-navbar-in-profile-dropdown {
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: auto;
  font-size: 14px;
  font-weight: 600;
}

.main-navbar-in-referrals-page .profile-drop .view-user{
    width: 100%;
}

.main-navbar-in-referrals-page .profile-drop .view-user hr{
    margin: 6px 0px;
}

.main-navbar-in-referrals-page :where(.css-dev-only-do-not-override-mxhywb).ant-avatar.ant-avatar-icon {
    cursor: pointer;
}

.main-navbar-in-referrals-page .profile-drop {
  gap: 0px !important;
  display: flex !important;
  justify-content: end;
}

.offCanva .navbar-in-profile-vive-but-small-screen {
  width: 50px;
  font-size: 10px;
}

.offCanva .profile-details .pi {
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: center;
  width: 70%;
}

.offCanva .offcanva-in-profile {
  display: flex;
  width: 100%;
  justify-content: space-around;
  /* align-items: center; */
  margin-top: 30px;
}
.main-navbar-in-referrals-page .mb-3 {
  margin: 0 !important;
}

.offCanva {
  padding: 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #F5F5F5;
  overflow-y: scroll;
  transition: all 1s ease;
}

.offCanva .offcanva-in-profile-in-skulls-img {
  width: 65px;
  /* padding:10px ; */
}

.offCanva .profile-details-in-viev-but {
  width: 70%;
  height: 40px;
  border-radius: 20px;
  /* margin-left: 10px; */
  outline: none;
  border: none;
  background-color: #265f9d;
  color: #ffffff;
}
 
.profile-details-in-logout-but {
  height: 40px;
  font-size: 20px;
  outline: none;
  border: none;
  color: black;
  font-weight: 800;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 120px;

}
.navbar-in-profile-Logout-but:hover{
  background-color: #265F9D;
  color: #FFFFFF;
}

.profile-details-in-logout-but:hover{
  background-color: #265F9D;
  color: #FFFFFF;
}

.offCanva .total-referrals-num {
  color: #265f9d;
  font-weight: 600;
  font-size: 23px;
}

.offCanva .total-earnings-num {
  color: #ebc400;
  font-weight: 600;
  font-size: 23px;
}



.logoutbut-befor-upperline {
  /* margin-top: 100%; */
}
.offcanvas-header .btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 15px;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 425px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .refer-on-requirement-section {
    display: flex;
    justify-content: center;
  }

  .refer-on-requirement-section .state-search-field input{
    font-weight: 400 !important;
  }

  .refer-on-requirement-section .refer-many-sec {
    padding: 100px 120px 20px 120px;
  }

  .refer-submit-form .MuiFormLabel-root {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    font-size: 1rem;
    top: -9px;
  }

  .refer-submit-form .MuiInputLabel-shrink{
    top: 0px !important;
  }

  .refer-submit-form .MuiInputBase-input{
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em !important;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    height: 5px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 12.5px 14px !important;
  }

  .refer-submit-form .MuiInputBase-root .Mui-disabled{
    -webkit-text-fill-color: #000000 !important;
    font-size: 14px !important;
    height: 1.4375em !important;
    font-weight: bolder;
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .refer-on-requirement-section .refer-requirement-click-btn {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .refer-on-requirement-section .refer-requirement-click-btn button {
    height: 50px;
    width: 130px;
    border: 1px solid lightgray;
    border-radius: 10px;
    color: white;
    background-color: #265f9d;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

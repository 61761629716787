.add-required-click button {
  height: 48px;
  width: 220px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background-color: white;
  font-size: 15px;
  font-weight: 400;
  transition: all 0.5s ease;
}

.add-required-click button:hover {
  background-color: #265f9d;
  color: white;
}

.adding-required-modal .ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0px;
}

.adding-required-modal .ant-modal-root .ant-modal-mask {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgb(0 0 0 / 77%);
}

.adding-required-modal .ant-modal-close {
  position: absolute;
  top: -25px;
  right: -30px;
  border: 3px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adding-required-modal .ant-modal-close span {
  font-weight: bold;
  line-height: 0px;
  font-size: 16px;
}

.adding-required-modal .ant-modal-close:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.06);
  text-decoration: none;
}

.add-requirement-all-section .all-requirement-header {
  height: 60px;
  width: 100%;
  background-color: lightgray;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-requirement-all-section .all-requirement-header p {
  font-size: 15px;
  font-weight: 700;
  margin: 0px;
}

.required-text-field-section .MuiFormLabel-root {
  color: rgba(161, 161, 161, 0.6);
}

.required-text-field-section .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 10px;
  top: -5px;
  transform: translate(14px, 16px) scale(1);
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}

.required-text-field-section .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 1px solid lightgray;
  box-sizing: content-box;
  background: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  height: 35px;
  border-radius: 6px;
  outline: none;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 6.5px 10px;
}

.required-code-duration .first-required-section {
  display: flex;
  gap: 50px;
  margin: 0px;
  justify-content: space-between;
}

.required-code-duration .first-required-section input[type="radio"] {
  accent-color: black;
}

.required-code-duration .first-required-section .MuiFormControl-root {
  width: 45%;
}

/* /// Job Type /// */

.first-required-section .requirement-job-type .first-section {
  position: relative;
  height: 48px;
  width: 310px;
  border-radius: 8px;
  /* border: 1px solid lightgray; */
  outline: none;
  color: #808080bd;
}

.first-required-section .requirement-job-type .first-section label {
  position: absolute;
  bottom: 35px;
  z-index: 1;
  /* left: 2em; */
  background-color: white;
  padding: 0 5px;
  font-size: 12px;
}

.first-required-section .requirement-job-type .remote-to-onsite {
  display: flex;
  gap: 18px;
  position: relative;
  bottom: 35px;
  padding-left: 10px;
  color: #80808096;
}

.requirement-job-type .remote-to-onsite .first-rdo {
  display: flex;
  gap: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.requirement-job-type .remote-to-onsite .first-rdo input {
  height: 15px;
  width: 15px;
  cursor:pointer;
}

/* /// Project Type ///  */

.first-required-section .project-type .second-section {
  height: 48px;
  width: 250px;
  border-radius: 8px;
  /* border: 1px solid lightgray; */
  position: relative;
  outline: none;
  color: #808080bd;
}

.first-required-section .project-type .second-section label {
  position: absolute;
  bottom: 35px;
  z-index: 1;
  /* left: 2em; */
  background-color: white;
  padding: 0 5px;
  font-size: 12px;
}

.first-required-section .project-type .contract-full-time {
  display: flex;
  gap: 18px;
  position: relative;
  bottom: 35px;
  padding-left: 10px;
  color: #80808096;
}

.project-type .contract-full-time .last-rdo {
  display: flex;
  gap: 5px;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.project-type .contract-full-time .last-rdo input {
  height: 15px;
  width: 15px;
  cursor: pointer;
}

.required-code-duration .second-required-title-section {
  display: flex;
  gap: 80px;
  margin: 0px;
  justify-content: space-between;
  margin-top: 20px;
}

.required-code-duration .second-required-title-section .MuiTextField-root {
  width: 45%;
}

.required-code-duration .second-required-title-section .MuiFormControl-root {
  width: 390px;
}

.required-code-duration .third-four-required-section textarea {
  height: 80px;
  width: 100%;
  border-radius: 8px;
  margin-top: 0px;
  padding-left: 25px;
  padding-top: 10px;
  font-size: 15px;
  resize: none;
  outline: none;
  border-color: lightgray;
}

.required-code-duration .third-four-detailed-section textarea {
  height: 80px;
  width: 100%;
  border-radius: 8px;
  margin-top: 15px;
  padding-left: 25px;
  padding-top: 10px;
  font-size: 15px;
  resize: none;
  outline: none;
  border-color: lightgray;
}

.required-code-duration .required-submit-btn {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.required-code-duration .required-submit-btn >button {
  height: 50px;
  width: 130px;
  border-radius: 5px;
  border: 1px solid lightgray;
  background-color: white;
  font-size: 20px;
  background: transparent;
}

.required-code-duration .required-submit-btn #submit-btn:hover {
  color: white;
  background-color: #265f9d;
  border: none;
  transition: none 0.8s; 
}

.required-code-duration .required-submit-btn #edit-btn {
  background-color:#0e9280;
  color:white; 
  border: none;

}
.required-code-duration .required-submit-btn #edit-btn:hover {
  /* background-color: #265f9d; */
  transition:none; 

}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  max-height: calc(45% - 17px) !important;
}

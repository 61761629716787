.login-page-main-component .phone-input::placeholder {
  font-size: 13px !important;
  top: -5px !important;
  color: #c5c5c5 !important;
}
/* .incorrectEmail{
  font-size: 10px;
} */

.login-page-main-component {
  background-image: url("../assets/Layer\ 2.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* background: #f6f6f6 0% 0% no-repeat padding-box; */
}

.login-page-main-component .wiseskulls-login-logo img {
  width: 20%;
  padding: 1% 1% 1% 4%;
}

.login-page-main-component .lacture-and-login-holder {
  display: flex;
  padding-right: calc(var(--bs-gutter-x) * 1.5) !important;
  padding-left: calc(var(--bs-gutter-x) * 2) !important;
}

.lacture-and-login-holder .wiseskulls-hey-there {
  padding: 7% 3% 1%;
  width: 60%;
}

.lacture-and-login-holder .wiseskulls-hey-there h1 {
  text-align: left;
  font: normal normal bold 45px/45px Poppins;
  color: #000000;
}

.lacture-and-login-holder .wiseskulls-hey-there h3 {
  text-align: left;
  font: normal normal bold 30px/40px Poppins;
  color: #0e4d92;
}

.lacture-and-login-holder .wiseskulls-hey-there p {
  font-size: 18px;
  line-height: 23px;
  font-weight: 600;
  width: 84.5%;
}

.lacture-and-login-holder .wiseskulls-hey-there img {
  width: 80%;
}

.lacture-and-login-holder .wiseskulls-signup-signin {
  width: 40%;
  padding: 10px 20px;
  margin-top: -2%;
}

.wiseskulls-signup-signin .signup-signin-holder {
  display: flex;
  justify-content: space-between;
  margin-bottom: 6%;
}

.wiseskulls-signup-signin .signup-signin-holder button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 48%;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.wiseskulls-signup-signin .signup-signin-holder .sign-up-button {
  color: white;
  background: #265f9d 0% 0% no-repeat padding-box;
}

.wiseskulls-signup-signin .signup-signin-holder .sign-up-button:hover {
  color: white;
  background: #265f9d 0% 0% no-repeat padding-box;
}

.wiseskulls-signup-signin .signup-signin-holder .sign-in-button {
  background-color: #0e9280;
  color: white;
}

.wiseskulls-signup-signin .signup-signin-holder .sign-in-button:hover {
  background-color: #0e9280;
  color: white;
}

.wiseskulls-signup-signin .signup-signin-holder .inActive {
  color: black;
  background-color: white;
  box-shadow: 0 0 1px 0;
}

.wiseskulls-signup-signin .form-holder {
  padding: 20px 20px 50px;
  height: max-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 10px #00000029;
  border-radius: 10px;
  transition: all 1s ease-in;
}

.wiseskulls-signup-signin .form-holder .MuiBox-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wiseskulls-signup-signin .form-holder > p {
  font-size: 28px;
  margin-top: 1% !important;
  margin-bottom: 1% !important;
  color: #265f9d;
}

.wiseskulls-signup-signin .form-holder .MuiInputBase-root {
  border-radius: 10px;
}

.wiseskulls-signup-signin .form-holder input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.wiseskulls-signup-signin .form-holder .usaflag-or-usernumber {
  width: 87%;
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  height: 40px;
  border-radius: 10px;
}

.wiseskulls-signup-signin .form-holder .usaflag-or-usernumber .giusaflag {
  width: 40px;
  padding: 5px;
  border-right: 1px solid lightgray;
}

.wiseskulls-signup-signin .form-holder .usaflag-or-usernumber .phone-input {
  border: none;
  padding: 0 19px;
  width: 61%;
  outline: none;
}
.wiseskulls-signup-signin
  .form-holder
  .usaflag-or-usernumber
  .phone-input::placeholder {
  font-size: 15px;
  color: #c5c5c5;
}

.form-holder .MuiBox-root .enter-otp {
  border: none;
  padding: 0 19px;
  outline: none;
  width: 75%;
}

.form-holder .MuiBox-root .enter-otp::placeholder {
  font-size: 15px;
  color: #c5c5c5;
}

.usaflag-or-usernumber .verify-btn,
.validate {
  position: absolute !important;
  right: 0 !important;
  border: none !important;
  outline: none !important;
  border-left: 1px solid lightgray !important;
  height: 100% !important;
  background: white !important;
  border-radius: 0 10px 10px 0 !important;
  width: 18% !important;
  color: #265f9d;
  font-size: 13px !important;
  font-weight: 500;
}

.usaflag-or-usernumber .checkCircle-after-verify {
  position: absolute !important;
  right: 25px !important;
  top: 1px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  width: 18% !important;
  height: 100% !important;
  justify-content: space-evenly !important;
  color: #0e9280 !important;
  /* border-left: 1px solid lightgray !important; */
}

.usaflag-or-usernumber .checkCircle-after-verify .aiFillCheckCircle {
  font-size: 20px !important;
}
.usaflag-or-usernumber .checkCircle-after-verify .verified {
  margin: 0 !important;
  font-size: 10px !important;
  font-weight: 500 !important;
}

.wiseskulls-signup-signin .form-holder button {
  width: 65%;
  height: 50px;
  border-radius: 10px;
  border: none;
  background: #265f9d 0% 0% no-repeat padding-box;
  color: white;
  font-size: 18px;
  outline: none;
}

.wiseskulls-signup-signin .form-holder .fgt-button {
  background: white !important;

  text-decoration: underline !important;
  font-weight: 300 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}

.wiseskulls-signup-signin .form-holder .MuiInputBase-input {
  height: 0.8em !important;
  padding: 13px !important;
}

.wiseskulls-signup-signin .form-holder .MuiFormLabel-root {
  font-size: 14px !important;
  top: -7px !important;
  color: #c5c5c5 !important;
}

.wiseskulls-signup-signin .form-holder .MuiInputLabel-shrink {
  top: 0px !important;
}

.wiseskulls-signup-signin .form-holder .MuiSelect-select {
  padding: 8.5px 14px !important;
}

.wiseskulls-signup-signin .form-holder .MuiInputBase-root {
  border-radius: 10px !important;
}

.wiseskulls-signup-signin .form-holder .forgot-password {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-main-component .signup-aboutus-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signup-aboutus-main-div .how-does-this-work {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: calc(var(--bs-gutter-x) * 1.5) !important;
  padding-left: calc(var(--bs-gutter-x) * 2) !important;
  position: relative;
  margin: 3% 0;
}

.signup-aboutus-main-div .how-does-this-work p {
  background: #265f9d 0% 0% no-repeat padding-box;
  font: normal normal 300 25px Poppins;
  color: #ffffff;
  margin: 0;
  height: 60px;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: relative;
  left: 2.5%;
}

.signup-aboutus-main-div .how-does-this-work img {
  width: 41%;
}

.signup-aboutus-main-div .how-does-this-work i {
  position: absolute;
  bottom: 0;
  left: 6%;
  text-align: left;
  font: italic normal 700 21px/40px Poppins;
  color: #000000;
}

.signup-aboutus-main-div .signup-aboutUs-paragraph {
  padding: 2% 8% 0 7%;
}

.signup-aboutus-main-div .signup-aboutUs-paragraph ul li {
  font-size: 18.5px;
  font-weight: 600;
  line-height: 30px;
}

.signup-aboutus-main-div .signup-aboutUs-paragraph ul li::marker {
  font-size: 27px;
}

.signup-aboutus-main-div .signup-aboutUs-paragraph p {
  font-size: 19px;
  font-weight: 600;
  line-height: 30px;
}

.signup-aboutus-main-div .signup-aboutUs-paragraph p span {
  background: #265f9d 0% 0% no-repeat padding-box;
  color: #ffffff;
  padding: 2px;
  border-radius: 5px;
}

.signup-aboutus-main-div .signup-footer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 80ch;
  background: transparent linear-gradient(180deg, #f9fcff00 0%, #265f9d 100%) 0%
    0% no-repeat padding-box;
  margin-bottom: 0 !important;
}

.signup-aboutus-main-div .signup-footer .maskGroup2 {
  width: 50%;
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
}

.signup-aboutus-main-div .signup-footer .letsGrowTogether {
  position: absolute;
  bottom: 47%;
  font-size: 35px;
  margin: 0 !important;
}

.signup-aboutus-main-div .signup-footer .group656 {
  position: absolute;
  width: 85%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
}

.signup-aboutus-main-div .signup-footer .group652 {
  position: absolute;
  bottom: 40%;
  width: 15%;
}

.signup-aboutus-main-div .signup-footer .signup-contactDetails {
  background: transparent linear-gradient(180deg, #0e4d9200 0%, #072749 100%) 0%
    0% no-repeat padding-box;
  width: 100%;
  height: 220px;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.signup-contactDetails .icon-holder {
  background: #000000 0% 0% no-repeat padding-box;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  top: -27px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-contactDetails .icon-holder .computerDesktop {
  width: 35px;
  height: 35px;
  color: #ffffff;
}

.signup-contactDetails .getInTouch {
  font: normal normal normal 25px Poppins;
  letter-spacing: 0px;
  color: #f9fcff;
  margin-bottom: 0 !important;
  margin-top: 5%;
}

.signup-contactDetails .phone-WebSite-email-holder {
  display: flex;
  justify-content: space-around;
  align-items: start;
  width: 100%;
  height: 25%;
  font-size: 19px;
}

.signup-contactDetails .phone-WebSite-email-holder p {
  margin-bottom: 0 !important;
  color: white;
  font-weight: 300;
}

.signup-contactDetails .phone-WebSite-email-holder .phone-icone-number-holder {
  width: 20%;
  display: flex;
  justify-content: start;
  align-items: center;
  color: white;
  gap: 4%;
}

.signup-contactDetails .phone-WebSite-email-holder .mail-icone-email-holder {
  width: 20%;
  display: flex;
  justify-content: end;
  align-items: center;
  color: white;
  gap: 4%;
}

.singIn-main-section .otp-verification-section p {
  font: normal normal 300 16px/47px Poppins;
  letter-spacing: 0px;
  color: #000000;
  display: flex;
  justify-content: center;
}

.singIn-main-section .otp-verification-section .enterOTP-confirm-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otp-verification-section .enterOTP-confirm-btn input {
  width: 50%;
  height: 50px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border: 1px solid #cccccc;
  outline: none;
  text-align: center;
}

.otp-verification-section .enterOTP-confirm-btn button {
  width: 48%;
}
.verification-popup-show {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.verification-popup-show .bsCheck {
  background: #0e9280 0% 0% no-repeat padding-box;
  width: 25px;
  height: 25px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  color: white;
  align-items: center;
}
.verification-popup-show p {
  font: normal normal 300 16px/47px Poppins !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
}

.otp-sent-title {
  color: black !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 40px !important;
  text-align: center;
}
.wiseskulls-signup-signin
  .MuiFormControl-root
  .MuiTextField-root
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  outline: none;
  border: none;
}

.state-search-field div {
  padding: 0 !important;
}

.state-search-field div div button {
  display: none !important;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .signup-aboutus-main-div .signup-aboutUs-paragraph ul li {
    font-size: 15px;
  }

  .signup-aboutus-main-div .signup-aboutUs-paragraph p {
    font-size: 15px;
  }

  .login-page-main-component {
    background-attachment: fixed;
  }
  .login-page-main-component .wiseskulls-login-logo .group831 {
    /* width: 28%; */
    padding: 1% 6% 1% 6%;
  }

  .login-page-main-component .wiseskulls-login-logo {
    display: flex;
    justify-content: space-between;
    padding: 2%;
    align-items: center;
  }

  .login-page-main-component .wiseskulls-login-logo .wiseskulls-signup-signin {
    width: 40%;
  }

  .login-page-main-component .lacture-and-login-holder {
    display: block;
    padding: 0;
  }

  .lacture-and-login-holder .wiseskulls-hey-there {
    width: fit-content;
  }
  .lacture-and-login-holder .wiseskulls-hey-there img {
    width: 100%;
  }

  .signup-aboutus-main-div .signup-footer {
    height: 60ch;
  }

  .signup-aboutus-main-div .signup-footer .maskGroup2 {
    width: 60%;
  }
  .signup-aboutus-main-div .signup-footer .letsGrowTogether {
    font-size: 20px;
    bottom: 60% !important;
  }

  .signup-aboutus-main-div .signup-footer .group652 {
    bottom: 55%;
    width: 20%;
  }

  .signup-aboutus-main-div .how-does-this-work {
    width: 100% !important;
  }

  .signup-aboutus-main-div .how-does-this-work p {
    width: 50%;
  }

  .signup-aboutus-main-div .how-does-this-work i {
    font: italic normal 700 17px/40px Poppins;
    left: 9%;
  }

  .signup-contactDetails .getInTouch {
    margin-top: 10%;
    font: normal normal normal 20px Poppins;
  }

  .signup-contactDetails .phone-WebSite-email-holder {
    font-size: 15px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 425px) {
  .login-page-main-component .signup-aboutus-main-div .how-does-this-work {
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    gap: 25px;
    margin: 5% 0;
  }

  .signup-aboutus-main-div .how-does-this-work img {
    width: 100% !important;
  }

  .login-page-main-component .signup-aboutus-main-div .how-does-this-work i {
    position: relative;
    left: 0;
    font-size: 15px;
  }

  .login-page-main-component {
    background-position: center;
  }
  .wiseskulls-signup-signin .signup-signin-holder button {
    font-size: 16px;
    height: 40px;
  }
  .login-page-main-component .wiseskulls-login-logo .wiseskullsblue {
    width: 34%;
    padding: 0;
  }

  .signup-aboutus-main-div .signup-footer {
    height: 30ch !important;
  }

  .signup-aboutus-main-div .signup-footer .maskGroup2 {
    width: 70%;
  }

  .signup-aboutus-main-div .signup-footer .letsGrowTogether {
    bottom: 35% !important;
  }

  .signup-aboutus-main-div .signup-footer .group652 {
    width: 25%;
    bottom: 30px;
  }

  .login-page-main-component .lacture-and-login-holder {
    /* padding: 0 !important; */
    padding: 0 8% 0 7% !important;
  }

  .lacture-and-login-holder .wiseskulls-hey-there h1 {
    font-size: 25pt;
  }
  .lacture-and-login-holder .wiseskulls-hey-there h3 {
    font-size: 11pt;
    line-height: 20px;
  }
  .lacture-and-login-holder .wiseskulls-hey-there p {
    font-size: 11pt !important;
    line-height: 20px !important;
  }

  .signup-aboutus-main-div .how-does-this-work p {
    font-size: 11pt;
    width: 100% !important;
  }

  .signup-aboutus-main-div .signup-aboutUs-paragraph p {
    font-size: 11pt;
    line-height: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
  .lacture-and-login-holder .wiseskulls-hey-there p {
    font-size: 20px;
    line-height: 25px;
    width: 100%;
  }
  .wiseskulls-signup-signin .signup-signin-holder {
    margin: 0;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
  .wiseskulls-signup-signin .form-holder p {
    text-align: center;
  }

  .lacture-and-login-holder .wiseskulls-signup-signin {
    width: 50% !important;
  }

  .usaflag-or-usernumber .verify-btn,
  .validate {
    width: 25% !important;
    /* border-right: 1px solid lightgray !important; */
  }

  .lacture-and-login-holder .wiseskulls-hey-there p {
    margin: 0;
    width: 100%;
  }
}

.add-reference-click button {
  height: 40px;
  width: 160px;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid lightgray;
  font-size: 13px;
}

.add-reference-click button:hover {
  background-color: #0e9280;
  color: white;
  border: none;
  transition: 0.5s all;
}

.reference-edit-details .ant-modal .ant-modal-close:hover {
  background-color: transparent;
}

.reference-edit-details .ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0px 0px !important;
}

.reference-edit-details .ant-modal-content {
  padding: 0 !important;
}

.reference-edit-details .ant-modal-close:hover {
  background-color: transparent;
  cursor: pointer;
}

.new-reference-header-cancel {
  position: absolute;
  right: -2rem;
  font-size: 27px !important;
  top: -2.3rem;
  color: white;
  cursor: pointer;
}

.new-reference-all-section .all-input-name-section .form-autocomplete {
  width: 40% !important;
  margin: 3% 5% 1% 5%;
}

.new-reference-all-section
  .all-input-name-section
  .form-autocomplete
  .auto-text {
  margin: 0px;
  width: 100%;
}

.new-reference-all-section
  .all-input-name-section
  .form-autocomplete
  .auto-text
  .MuiInputBase-root {
  padding: 0px;
}

.new-refer-section .refer-all-id-input .form-autocomplete {
  width: 40% !important;
  margin: 3% 5% 1% 5%;
}

.new-refer-section .refer-all-id-input .form-autocomplete .auto-text {
  margin: 0px;
  width: 100%;
}

.new-refer-section
  .refer-all-id-input
  .form-autocomplete
  .auto-text
  .MuiInputBase-root {
  padding: 0px;
}

.reference-edit-details .ant-modal-close {
  position: absolute;
  top: -25px;
  right: -30px;
  border: 3px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.reference-edit-details .ant-modal-close span {
  font-weight: bold;
  line-height: 0px;
  font-size: 15px;
}

.reference-edit-details:where(.css-dev-only-do-not-override-1e3x2xa).ant-modal
  .ant-modal-close:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.06);
  text-decoration: none;
}

.new-reference-all-section .new-reference-header {
  height: 48px;
  width: 100%;
  background-color: lightgray;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-reference-all-section .new-reference-header p {
  font-size: 14px;
  font-weight: 700;
  margin: 0px;
}

.all-input-name-section .name-and-title-part {
  display: flex;
  justify-content: space-around;
}

.name-and-title-part .css-brpa64 {
  display: flex;
  flex-wrap: wrap;
}

.textfield-input-name .MuiFormLabel-root {
  color: rgba(161, 161, 161, 0.6);
  font-weight: 400;
  font-size: 15px;
  top: -8px;
}

.textfield-input-name .MuiInputLabel-shrink {
  top: 0px !important;
}

.textfield-input-name .MuiInputBase-root {
  border-radius: 5px;
}

.textfield-input-name .MuiInputBase-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 6.5px 40px 6.5px 10px !important;
}

.textfield-input-name .MuiFormControl-root {
  margin: 0px;
  width: 40%;
  margin: 3% 5% 1% 5%;
}

.new-reference-all-section .reference-submit-btn {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 20px;
}

.new-reference-all-section .reference-submit-btn button {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  border: 1px solid lightgray;
  background-color: white;
  background: transparent;
  font-weight: 500;
}

.new-reference-all-section .reference-submit-btn button:hover {
  background-color: #265f9d;
  color: white;
  border: none;
}

.state_and_city_input div:first-child {
  padding: 6.5px 14px !important;
}


.main-profilesection-div{
    height: 100vh;
    margin-top: 10%;
}

.offCanva .profile-close{
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 20px;
}

.offCanva .profile-close:hover{
    color: black;
}

.main-profilesection-div .avtar-in-profile-details{
    margin-left: 10px;
   
}
.main-profilesection-div .logo-user-name{
    display: flex;
    gap: 20px;
}
.main-profilesection-div .total-earn-and-referrals-div{
    display: flex;
    justify-content: start;
    /* width: 10%; */
    margin-left: 23%;
}
.main-profilesection-div .total-earn-and-referrals-div-p-tag{
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: center;
}
.main-profilesection-div .user-details{
    padding-left: 30px;
}
.main-profilesection-div .user-bank-details{
    display: flex;
    gap: 20px;
    padding-left: 30px;
    /* justify-content: ; */
}
.main-profilesection-div .total-referrals-num{
    color: #265F9D;
    font-size: 20px;
}
.main-profilesection-div .total-earnings-num{
    color: #EBC400;
    font-size: 20px;


}
.note-click-btn button {
  width: 50px;
  border: 1px solid lightgray;
  border-radius: 10px;
  font-size: 15px;
  margin-top: 20px;
}

.note-modal-section .ant-modal-close {
  position: absolute;
  top: -25px;
  right: -30px;
  border: 3px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.note-modal-section .ant-modal-close span {
  font-weight: bold;
  line-height: 0px;
  font-size: 15px;
}

.note-modal-section:where(.css-dev-only-do-not-override-1e3x2xa).ant-modal
  .ant-modal-close:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.06);
  text-decoration: none;
}

.note-modal-section:where(.css-dev-only-do-not-override-mxhywb).ant-modal
  .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 25px 50px;
}

.note-modal-all-details .all-not-data {
  position: relative;
  top: 30px;
}

.note-modal-all-details {
  padding-left: 30px;
  padding-top: 20px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.note-modal-section .ant-modal-close{
    display: none !important;
}

.note-popUp-cancel{
  position: absolute;
  font-size: 25px;
  right: -2.3rem;
  top: -2.5rem;
  color: white;
  cursor: pointer;
}

* {
    padding: 0;
    margin: 0;
}

.hotReqInput{
    width: 80%;
    margin-top: 10px;
    height: 35px;
    outline: none;
    border-radius: 30px;
    border: 1px solid lightgray;
    padding-left: 15px;
    font-size: 15px;
}

.norequirementsMainDiv{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.noRequirementIcon{
    font-size: 40px;
}
.noRequirementText{
    font-size: 20px;
    font-weight: 600;
}

.hotRequirementMainDiv {
    height: calc(100vh - 68px);
    overflow-y: scroll;
    flex-basis: 28%;
    box-shadow: 1px 3px 33px #0000000D;
    z-index: 1;
}

.hotRequirementMainDiv::-webkit-scrollbar{
    display: none;
}

.hotRequirementHeader {
    border-bottom: 1px solid rgb(226, 226, 226);
    padding-top: 11px;
    padding-bottom: 12px;
    padding-left: 30px;
    font-weight: 700;
    font-size: 17px;
    color: #0E4D92;
    position: sticky;
    top: 0;
    background-color: white;
}

.hotRequirementMainDiv .hotRequirementcontaineDiv {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(226, 226, 226);
    border-top: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 28px;
    cursor: pointer;
}
.hotRequirementMainDiv .hotRequirementcontaineDiv1 {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(226, 226, 226);
    border-top: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding-left: 28px;
    background-color: #f5f5f5;
    cursor: pointer;
}

.hotRequirementMainDiv .containeFirstDiv h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    line-height: 1.2;
    color: #000000;
}

.hotRequirementMainDiv .containeSecoundDiv p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 500;
    color: #959595;
    
}

.hotRequirementMainDiv .containethirdDiv p{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500;
    color: #000000;
    opacity: 1;
}
.hotRequirementcontaineDiv:hover{
    background-color: #f5f5f5;
}

.hotRequirementLoader{
    position: relative;
    left: 10.5rem;
    top: 13rem;
}
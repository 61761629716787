.hired-rejected-closed-modal .ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0px;
}

.hired-status-main-section .change-status-header-section {
  height: 60px;
  width: 100%;
  background-color: #d3d3d345;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hired-status-main-section .change-status-header-section p {
  font-size: 15px;
  font-weight: 700;
  margin: 0px;
}

.hired-rejected-closed-modal .ant-modal-close {
  position: absolute;
  top: -25px;
  right: -30px;
  border: 3px solid white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hired-rejected-closed-modal .ant-modal-close span {
  font-weight: bold;
  line-height: 0px;
  font-size: 16px;
}

.hired-rejected-closed-modal:where(
    .css-dev-only-do-not-override-1vtf12y
  ).ant-modal
  .ant-modal-close:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.06);
  text-decoration: none;
}

.hired-status-main-section
  .add-status-note-details
  .type-your-test-here
  textarea {
  height: 320px;
  width: 93%;
  color: gray;
  padding-left: 35px;
  padding-top: 20px;
  border-radius: 5px;
  background-color: #d3d3d345;
  border: none;
  margin-top: 30px;
  margin-left: 30px;
  outline: none;
}

.hired-status-main-section
  .add-status-note-details
  .type-your-test-here
  textarea::placeholder {
  color: lightgray;
}

.hired-status-main-section .status-note-btn {
  display: flex;
  justify-content: center;
  padding-top: 35px;
  padding-bottom: 10px;
}

.hired-status-main-section .status-note-btn button {
  height: 45px;
  width: 110px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  background-color: white;
  border: 1px solid lightgray;
}

.hired-status-main-section .status-note-btn button:hover {
  color: white;
  background-color: #265f9d;
  /* transition: all 0.8s; */
}

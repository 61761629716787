.referrer-right-main-section {
  position: relative;
  height: 650px;
  width: 96%;
  margin: 20px 20px;
  border-radius: 8px;
  background-color: white;
  padding: 0 90px 0 90px;
  color: black;
}

.referrer-right-main-section .referrer-profile-image img {
  height: 60px;
  width: 60px;
  border-radius: 100%;
  margin-top: 20px;
  border: 1px solid lightgray;
}

.referrer-right-main-section .referrer-client-name-section {
  height: 70px;
  width: 350px;
  position: relative;
  left: 80px;
  bottom: 45px;
}

.referrer-right-main-section .referrer-client-name-section .client-job-id {
  display: flex;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
}

.referrer-client-name-section .person-referrer-placements {
  display: flex;
  gap: 40px;
}

.referrer-client-name-section .person-referrer-placements .number-referrer-sec {
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
}

.referrer-client-name-section .person-referrer-placements .placement-sec {
  display: flex;
  gap: 10px;
  font-size: 16px;
  font-weight: 500;
}

.referrer-right-main-section .referrerInputSec {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -20px;
}

.referrer-right-main-section .referrerInputSec .fullNameAndEmailIdSec {
  flex-basis: 50%;
}

.referrer-right-main-section .referrerInputSec .fullNameAndEmailIdSec p {
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 6px;
}

.referrer-right-main-section .bankDetailSec p {
  margin-bottom: 0px;
  margin-top: 30px;
}

.referrer-right-main-section .bankDetailSec span {
  font-weight: 500;
}

.referrer-right-main-section .linkedInSec {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.referrer-right-main-section .linkedInSec p {
  margin-bottom: 0px;
  margin-top: 2px;
  font-weight: 600;
}

.noteBoxInput {
  position: absolute;
  top: 16%;
  left: 0px;
  transform: matrix(0, 1, -1, 0, 0, 0);
}

.referrer-right-main-section .referrerNotBtn {
  position: absolute;
  top: 50%;
  right: -74px;
  transform: matrix(0, 1, -1, 0, 0, 0);
}

.referrer-right-main-section .referrerNotBtn button {
  width: 118px;
  height: 31px;
  background: #265f9d 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 50px 50px 0px 0px;
  border: none;
  outline: none;
font: normal normal normal 16px/25px Poppins;
letter-spacing: 0px;
}

.referrer-right-main-section .noteBoxInput .notes {
  transition: all 0.2s ease-in;
}

.referrer-right-main-section .noteBoxInput .notes .note-text-area {
  width: 0px;
  height: 493px;
  border: none;
  outline: none;
  resize: none;
  border: 1px solid #ffff;
  border-left: none;
  backdrop-filter: blur(30px);
  transition: all 0.2s ease-in;
  text-align: left;
  font: normal normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #8e8e8e;
}

.referrer-right-main-section .noteBoxInput .notes .note-text-on {
  margin-top: 25px;
  width: 512px;
  height: 453px;
  padding: 20px;
  background: transparent;
  border-radius: 0px 10px 10px 0px;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  resize: none;
  outline: none;
  transition: all 0.5s ease-in;
  border: 1px solid #7070708a;
  border-left: none;
}
/* Switch  button */

.active-inactive-sec .ant-switch.ant-switch-checked .ant-switch-inner {
  padding-inline-start: 3px !important;
  padding-inline-end: 20px !important;
}

.active-inactive-sec .ant-switch .ant-switch-inner {
  padding-inline-start: 21px !important;
  padding-inline-end: 3px !important;
}

.active-inactive-sec .ant-switch.ant-switch-checked {
  background-color: #0e9280 !important;
}

.active-inactive-sec .ant-switch.ant-switch-checked:hover {
  background-color: #0e9280 !important;
}

.referrer-right-main-section .referrerTablaDataFoam {
  height: 574px;
}

/* antd loader */

.referrer-spinner {
  position: absolute;
  top: 50%;
  left: 62%;
  transform: translate(-50%, -50%);
}

.referrer-right-main-section .onlyOneBankSec {
  display: flex;
  justify-content: center;
  margin-right: 227px;
  margin-bottom: -22px;
  margin-top: -12px;
}

.requirments-container {
  background: #f2f8fe 0% 0% no-repeat padding-box;
  height: 100%;
  min-height: calc(100vh - 65px);
  width: 100%;
  padding: 25px 40px;

}
.requirments-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}
.requirments-header-container > span {
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ececec;
  border-radius: 30px;
}

.requirments-header-container span > input {
  width: 350px;
  border: none;
  outline: none;
  height: 40px;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  padding-left: 30px;
}

.requirments-header-container span > input::placeholder {
  color: #c1c1c1;
  font-weight: 300;
}

.requirments-header-container span > button {
  border: none;
  background: white;
  height: 40px;
  width: 50px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  color: #8e8e8e;
  outline: none;
  padding-right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
}
.requirments-table-container {
  min-height: 520px;
  height: 100%;
  background-color: white;
  border-radius:12px!important;

}
.requirments-table-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 18px;
}

.requirments-table-container table thead > tr {
  width: 100%;
  background-color: #efefef;
}

.requirments-table-container table thead tr > th {
  width: 20%;
  color: #989898;
  height: 52px;
  color: #959595;
  text-align: start;
  font-weight: 400;
  font-size: 16px;
}
.requirments-table-container table thead tr > th:first-child {
  border-top-left-radius: 8px !important;
  padding-left: 50px;
}
.requirments-table-container table thead tr > th:last-child {
  border-top-right-radius: 8px !important;
}

.requirments-table-container table tbody tr > td:first-child {
  padding-left: 50px;
}
  .requirments-table-container  table thead tr > th:first-child {
    border-top-left-radius: 8px !important;
    padding-left: 50px;

  }
  .requirments-table-container  table thead tr > th:last-child {
    border-top-right-radius: 8px !important;
  }
  
  .requirments-table-container  table tbody tr > td:first-child {
    padding-left: 50px;
  
  }

  .requirments-table-container  table tbody > tr {
    background-color: white;
    border: 1px solid #ebebeb;
    text-align: start;
    height: 47px;
    color: #000000;
    font-size: 16px;
    font-weight: 500;
  }

  .requirment-del-span button{
    margin:  0px;
    border: none;
    background: none;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    }

.requirment-del-span >button:first-child{
  color:#265F9D;
}

.requirment-del-span >button:last-child{
    color:#BC0000;
 }

.requirments-table-container table tbody > tr {
  background-color: white;
  border: 1px solid #ebebeb;
  text-align: start;
  height: 47px;
  color: #000000;
  font-weight:400;
  font-size: 15px;;
}

.requirment-del-span p {
  margin: 0px;
}

.requirment-del-span > p:first-child {
  color: #265f9d;
}
.requirment-del-span > p:last-child {
  color: #bc0000;
  cursor: pointer;
}

.requirments-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #efefef;
  height: 56px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-right: 30px;
}

.requirments-pagination-container
  .requirments-pagination
  .MuiPagination-ul
  li
  button {
  border: 1px solid white;
  border-radius: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-weight: 600;
  margin: 0px !important;
}
.requirments-pagination-container
  .requirments-pagination
  .MuiPaginationItem-root {
  height: 40px !important;
  width: 42px !important;
}
.requirments-pagination-container
  .requirments-pagination
  .MuiPagination-ul
  li
  button.Mui-selected.Mui-selected {
  color: #fff;
  background: #265f9d 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 0 !important;
  border-radius: 0;
  margin: 0px !important;
}

.requirments-pagination-container .requirments-pagination-text > p {
  font-size: 14px;
  font-weight: 500;
  padding-left: 50px;
  padding-top: 15px;
}

/* .requirments-pagination-container
  .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root {
  margin: 0px !important;
} */

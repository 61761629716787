/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700;800&display=swap"); */
.candidate-container {
  background: #f2f8fe 0% 0% no-repeat padding-box;
  height: 100%;
  min-height: calc(100vh - 65px);
  width: 100%;
  padding: 14px 40px 14px 40px;
}
.candidate-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}
 .candidate-header-btn-box {
  display: flex;
  align-items: center;
  gap:10px;
 }

.candidate-header-btn-box > button {
  height: 48px;
  width: 200px;
  font-size: 15px;
  font-weight: 400;
  background: #265f9d 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.candidate-header-btn-box > button .status-span-btn-icon {
  color: "#FFFFFF";
}

.candidate-header-btn-box > button:first-child {
  height: 48px;
  width: 200px;
  border-radius: 8px;
  border: 1px solid lightgray;
  background-color: white;
  font-size: 15px;
  font-weight: 400;
  transition: all 0.5s ease;
  color: black;
}
.candidate-header-btn-box > button:first-child .lead-span-btn-icon {
  color: black;
}

/* .candidate-header-btn-box > button:hover {
  color: #FFFFFF;
  background-color: #0E9280;
  transition: all 0.3s ease-in-out;
} */
/* .candidate-header-btn-box > button:hover .status-span-btn {
  color: #FFFFFF;
  background-color: #0E9280;
  transition: all 0.3s ease-in-out;

} */
/* .candidate-header-btn-box > button:hover .lead-span-btn {
  color: #FFFFFF;
  background-color: #0E9280;
  transition: all 0.3s ease-in-out;

} */

/* 
.candidate-header-btn-box > button:hover .lead-span-btn {
  color: #FFFFFF;
  background-color: #0E9280;
  transition: all 0.3s ease-in-out;

} */
/* .candidate-header-btn-box > button:hover .status-span-btn-icon,.candidate-header-btn-box > button:hover .lead-span-btn-icon {
  color: white;
} */
.candidate-dropdown-container {
  position: absolute;
   width: 200px;
  height: fit-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  top: 55px;
  display: flex;
  flex-direction: column;
  z-index: 5;
}

.candidate-dropdown-container>button {
   width: 100%;
   height: 40px;
   color: #000000;
   background-color: white;
   padding: 10px 10px 10px 20px;
   font-size: 16px;
   font-weight: 400;
   cursor: pointer;
   border: none;
   text-align: left;
}
.candidate-dropdown-container>button:hover {
  background-color:#265f9d;
  color: white;
  transition:all 0.5s ease;
}
.candidate-dropdown-container>button:first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

}
.candidate-dropdown-container>button:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;

}

.candidate-header-container > button > span {
  color: #ffffff;
  background: #265f9d 0% 0% no-repeat padding-box;
  border: none;
  font-size: 16px;
}
.candidate-header-container > span {
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ececec;
  border-radius: 30px;
}

.candidate-header-container span > input {
  width: 350px;
  border: none;
  outline: none;
  height: 40px;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  padding-left: 30px;
}
.candidate-header-container span > input::placeholder {
  color: #c1c1c1;
  font-weight: 300;
}
.candidate-header-container span > button {
  border: none;
  background: white;
  height: 40px;
  width: 50px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  color: #8e8e8e;
  outline: none;
  padding-right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  cursor: pointer;
}
.candidate-table-container {
  min-height: 570px;
  height: 100%;
  background-color: white;
  border-radius: 12px !important;
  padding: 0px!important;
  margin: 0px!important;
  overflow-x:scroll;

}

.candidate-table-container table {
  border-collapse: collapse;
  padding-top: 0px!important;
  margin-top: 0px!important;
}

.candidate-table-container table thead > tr {
  width:fit-content;
  background-color: #efefef;
}
.candidate-table-container table thead tr > th {
  width:fit-content;
  color: #989898;
  height: 52px;
  color: #959595;
  text-align: start;
  font-weight: 400;
  white-space: nowrap;
  padding-left: 15px;
}
.candidate-table-container table thead tr > th:first-child {
  border-top-left-radius: 8px !important;
  padding:0px 10px 0px 20px;
}
.candidate-table-container table thead tr > th:last-child {
  border-top-right-radius: 8px !important;
}
.candidate-table-container table tbody > tr {
  background-color: white;
  border: 1px solid #ebebeb;
}

.candidate-table-container table tbody tr > td:first-child {
  padding:0px 10px 0px 20px;
}
.candidate-table-container table tbody tr > td:last-child {
  padding-right: 10px;
}
.candidate-table-container table tbody tr > td {
  text-overflow: ellipsis;
  width: fit-content;
  white-space: nowrap;
  height: 51px;
  padding-left:15px;
  text-align: start;
  color: #000000;
  font-size: 15px;
  font-weight: 400;

}



.candidate-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #efefef;
  height: 50px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-right: 30px;
}

.candidate-pagination-container
  .candidate-pagination
  .MuiPagination-ul
  li
  button {
  border: 1px solid white;
  border-radius: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-weight: 600;
  margin: 0px !important;
}
.candidate-pagination-container .candidate-pagination .MuiPaginationItem-root {
  height: 40px !important;
  width: 42px !important;
}
.candidate-pagination-container
  .candidate-pagination
  .MuiPagination-ul
  li
  button.Mui-selected {
  color: #fff;
  background: #265f9d 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 0 !important;
  border-radius: 0;
  margin: 0;
}
.candidate-pagination-container .candidate-pagination-container-text > p {
  font-size: 14px;
  font-weight: 500;
  padding-left: 50px;
  padding-top: 15px;
}

/* .candidate-pagination-container
  .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root {
  margin: 0px !important;
} */

.candidate-header-span {
  display: flex;
  align-items: center;
}
.candidate-header-span > input {
  width: 18px;
  height: 18px;
  border: 1px solid #707070;
  border-radius: 10px;
}


.candidate-id-td-checkbox .candidate-header-span > input{
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  border-radius: 10px;
}



.candidateTableSpiner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.candidate-nodata-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 32rem;
  top: 11rem;
}

.nodata-icon{
  font-size: 50px;
  /* color: darkgray; */
}
.nodata-text{
  font-weight: bold;
  font-size: 20px;
  white-space: nowrap;
  /* color: darkgray; */
}

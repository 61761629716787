.form-div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 50px 0px;
    background-image: url("../assets/Layer 2.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.form-div .para{
    margin-bottom: 50px;
    font-weight: 500;
}

.form-div .reference-submit-btn button{
    height: 35px;
  width: 90px;
  border-radius: 5px;
  color: white;
  border: 1px solid gray;
  background-color: #265f9d;
  margin: 50px 0px;
}
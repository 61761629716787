

.referrer-left-main-section .referrer-left-header {
  margin-top: 8px;
  margin-left: 60px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  /* padding-right: 60px; */
  margin-right: 60px;
}

.referrer-left-main-section .referrer-left-header p {
  font-size: 20px;
  font-weight: 700;
}

/* .referrer-left-header-Export{
  height: 30px;
  width: 100px;
  background-color: white;
  border: 1px solid darkgray;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
}
.referrer-left-header-Export:hover{
  color: white;
  background-color: #0E9280;
  transition: 0.5s all;
} */

.referrer-left-main-section .referrer-search-section input {
  margin-left: 45px;
  height: 40px;
  width: 330px;
  border: 1px solid lightgray;
  border-radius: 20px;
  padding-left: 35px;
  outline: none;
  margin-top: -10px;
}

.referrer-left-main-section .referrer-search-section input::placeholder{
  font-size: 13px !important;
}



.referrer-left-main-section .referrer-search-section input::placeholder {
  font-size: 15px;
  font-weight: 600;
  color: gray;
}

.referrer-left-main-section .referrer-search-section .search-icon {
  position: relative;
  right: 40px;
  font-size: 20px;
  bottom: 0px;
  color: gray;
}

.referrer-left-main-section .job-id-referrer-name-section {
  height: 45px;
  width: 100%;
  border: 1px solid lightgray;
  margin-top: 10px;
  color: white;
  background-color: #265f9d;
  display: flex;
  padding: 10px;
  font-size: 15px;
}

.referrer-left-main-section .job-id-referrer-name-section .job-header-section {
  width: 50%;
  padding-left: 40px;
}

.referrer-left-main-section .first-name-section {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
  cursor: pointer;
}


.referrer-left-main-section .first-name-section:hover{
  color: black !important;
  background-color:  #D6D6D6 !important;
  transition: 0.5s;
}


.referrer-left-main-section .second-data-section {
  overflow-y: scroll;
  height: 552px;
}

/* width */
.referrer-left-main-section .second-data-section::-webkit-scrollbar {
  width: 10px;
  background-color: #D6D6D6;
}

/* Track */
.second-data-section::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.second-data-section::-webkit-scrollbar-thumb {
  background: #EBEBEB;
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
.second-data-section::-webkit-scrollbar-thumb:hover {
  background: #EBEBEB;
}

.referrer-spinnerLoading {
  position: absolute;
  top: 65%;
  left: 15%;
  transform: translate(-50%, -50%);
}

.referrer-left-main-section .second-data-section .noDataFoundSec{
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 300px;
 gap: 5px;
}



.terms-list-box h1 {
  letter-spacing: 0px;
  color: #265f9d;
  /* font: normal normal bold 50px/50px Poppins; */
}

.media-terms-container {
  padding: 60px;
}
.media-terms-box {
  margin: 20px 45px;
}

.terms-list-box .terms-list li {
  margin: 25px 0;
  /* font: normal normal normal 20px/30px Poppins; */
  letter-spacing: 0px;
  color: #000000;
  font-weight: 500;
}

.terms-list-box .terms-list li::marker {
  font-size: 25px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .media-terms-box {
    margin-left: 0px !important;
  }
  .media-terms-container {
    padding: 0px;
  }
  .terms-list-box h1 {
    text-align: center;
    font-size: 22px;
  }

  .terms-list{
      padding-right: 10px !important;
      padding-left: 2.5rem!important;
      font-size: 13px;
  }

  .terms-list li{
    margin: 20px 0;
}

}

.userDashboard-pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #efefef;
  height: 50px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-right: 30px;
}

.userDashboard-pagination-container
  .userDashboard-pagination
  .MuiPagination-ul
  li
  button {
  border: 1px solid white;
  border-radius: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  font-weight: 600;
  margin: 0px !important;
}
.userDashboard-pagination-container
  .userDashboard-pagination
  .MuiPaginationItem-root {
  height: 40px !important;
  width: 42px !important;
}
.userDashboard-pagination-container
  .userDashboard-pagination
  .MuiPagination-ul
  li
  button.Mui-selected {
  color: #fff;
  background: #265f9d 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 0 !important;
  border-radius: 0;
  margin: 0px !important;
}
.userDashboard-pagination-container
  .userDashboard-pagination-container-text
  > p {
  font-size: 14px;
  font-weight: 500;
  padding-left: 50px;
  padding-top: 15px;
}

.userDashboard-pagination-container
  .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root {
  margin: 0px !important;
}

.client-refer-for-job .ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0px !important;
}

.client-refer-for-job .ant-modal-content {
  padding: 0 !important;
}

.refer-textfield-all-input .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #000000 !important;
  font-size: 14px !important;
  height: 1.4375em !important;
  font-weight: bolder;
  padding-left: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.client-refer-for-job .ant-modal-close {
  position: absolute;
  top: -20px;
  right: -25px;
  border: 3px solid white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: 600 !important; */
  display: none !important;
}

.new-refer-section .new-refer-header .newRefCancel {
  position: absolute;
  /* left: 23rem;
  bottom: 3rem; */
  right: -2rem;
  bottom: 27.5rem;
  font-size: 25px !important;
  color: white;
  cursor: pointer;
}

.client-refer-for-job .ant-modal-close span {
  font-weight: bold;
  line-height: 0px;
  font-size: 15px;
}

.client-refer-for-job .ant-modal .ant-modal-close:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.06);
  text-decoration: none;
}

.new-refer-section {
  height: max-content !important;
  padding-bottom: 20px !important;
}

.new-refer-section .new-refer-header {
  height: 48px;
  width: 100%;
  background-color: lightgray;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-refer-section .new-refer-header p {
  font-size: 14px;
  font-weight: 700;
  margin: 0px;
}

.refer-all-id-input {
  display: flex;
  justify-content: space-around;
  height: max-content;
}

.refer-all-id-input .css-brpa64 {
  display: flex;
  flex-wrap: wrap;
}

.refer-all-id-input .refer-textfield-all-input .MuiFormLabel-root {
  color: rgba(161, 161, 161, 0.6);
  font-weight: 400;
  font-size: 15px;
  top: -8px;
}

.refer-all-id-input .refer-name-job-id .last-city-sec {
  display: flex;
  width: 100%;
  position: relative;
  bottom: 10px;
}

.refer-textfield-all-input .MuiInputLabel-shrink {
  top: 0px !important;
}

.refer-textfield-all-input  .refer-CityClass .MuiInputBase-root .Mui-disabled{
  font-weight: 400 !important;
  font-size: 16px !important;
  -webkit-text-fill-color: #c4c4c4 !important;
}

.refer-textfield-all-input .MuiInputBase-root {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  border-radius: 5px;
}

.refer-textfield-all-input .MuiInputBase-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 6.5px 10px;
  padding-left: 9px !important;
}

.refer-textfield-all-input .MuiFormControl-root {
  margin: 0px;
  width: 40%;
  margin: 3% 5% 0% 5%;
}

.new-refer-section .new-refer-submit-btn {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 20px;
}

.new-refer-section .new-refer-submit-btn button {
  height: 40px;
  width: 100px;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid lightgray;
  background-color: white;
  /* margin-top: 30px;
    margin-bottom: 30px; */
}

.new-refer-section .new-refer-submit-btn button:hover {
  background-color: #265f9d;
  color: white;
  border: none;
}

.requirement-main-box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  left: 0;
}

.job-title .jobStatusReq{
  font-weight: 400 !important;
  padding-left: 12px;
  margin: 0 !important;
  font-size: 14px !important;
}

.bold-words-and-refer-click .cancel-but {
  font-size: 28px;
  position: absolute;
  right: 40px;
  top: 20px;
  color: white;
}

.requirement-main-box .required-box {
  background-color: white;
  height: 85%;
  overflow-y: scroll;
  width: 85%;
  border: 1px solid gray;
  border-radius: 8px;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 30px;
}

.requirement-main-box .required-box::-webkit-scrollbar {
  display: none;
}

.requirement-main-box .required-box .job-title {
  display: flex;
  font-size: 14px;
}

.requirement-main-box .required-box .job-title p {
  color: gray;
  font-size: 15px;
  font-weight: 600;
  margin-right: 8px;
}

.requirement-main-box .required-box .bold-words-and-refer-click {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}
.requirement-main-box .required-box .bold-duration-title{
  width: 100% !important;
}

.requirement-main-box .required-box .all-bold-title {
  padding-left: 95px;
  margin-top: 10px;
  width: 100% !important;
  font-size: 14px;
}

.requirement-main-box .required-box .all-bold-title .bold-required-title{
  width: 100% !important;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
}

.requirement-main-box .required-box .refer-job-btn button {
  position: absolute;
  top: 12%;
  right: 13%;
  height: 40px;
  width: 140px;
  border: 1px solid gray;
  border-radius: 7px;
  font-size: 12px;
  color: white;
  background-color: #265f9d;
  border: none;
  outline: none;
}

.required-loader {
  position: relative;
  left: 24.5rem;
  top: 14rem;
}

.jobCodeText{
  color: black !important;
  font-size: 14px !important;
  color: #808080 !important;
}
                   
/* .bold-detailed-title {
  display: flex;
  flex-direction: column;
}                    */
.bold-detailed-title{
  text-align: start;
  width: 100% !important;
}

.bold-detailed-title >b {
  white-space: nowrap;
  text-align: start;
}
.bold-detailed-title>textarea {
  resize: none;
  width: 500px;
  height: 410px;
  overflow: hidden;
  background: white;
  border: white;
  color: black;
  font-size: 15px;
}

textarea::first-letter{
  color: red !important;
  font-size: large !important;
  content :"Detailed" !important;
}
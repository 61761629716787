.referredUsersMainDiv {
  position: relative;
  background-color: #f2f8fe;
  /* width: 100%; */
  flex-basis: 72%;
  padding: 0px 2%;
}

.referradHyperLink{
  color: black !important;
}

.noDataFoundDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 15rem;
  top: 7rem;
}

.noDataIcon {
  font-size: 50px;
  /* color: darkgray; */
}
.noDataText {
  font-weight: bold;
  font-size: 20px;
  /* color: darkgray; */
}

.referredUsersMainDiv .referredAndEarningMainDiv {
  position: relative;
  height: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 1%;
}

.referredUsersMainDiv .referredAndEarningMainDiv .totalReferralsDiv {
  border: 2px solid #ebebeb;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 100px;
  padding-top: 20px;
  padding-bottom: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
}

.referredUsersMainDiv .referredAndEarningMainDiv .totalReferralsDiv p {
  margin-bottom: 0;
  text-align: center;
}

.totalReferralsFirstp {
  font-size: 30px;
  font-weight: 700;
  color: #265f9d;
}

.totalReferralsSecoundp {
  font-weight: 700;
}

.referredUsersMainDiv .referredAndEarningMainDiv .earningsDiv {
  position: relative;
  border: 2px solid #ebebeb;
  width: 220px;
  height: 100px;
  padding-bottom: 10px;
  background-color: white;
  border-radius: 10px;
}

.referredUsersMainDiv .referredAndEarningMainDiv .earningsDiv .earningContaine {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: center;
  padding-top: 20px;
}

.referredUsersMainDiv .referredAndEarningMainDiv .earningsDiv p {
  margin-bottom: -4px;
}

.earningsIcon {
  text-align: end !important;
  padding-right: 10px;
  font-size: 20px;
  color: #ebc400;
  position: absolute;
  top: 0;
  right: 0;
}

.earningContaineFirstP {
  color: #ebc400;
  font-size: 30px;
  font-weight: 700;
}

.earningContaineSecoundP {
  font-weight: 700;
}

.addReferenceMainDivContaine {
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
  position: absolute;
  bottom: -25%;
  right: 0;
}

.referredUsersMainDiv .referredAndAddreferenceMinDiv {
  display: flex;
  height: 5%;
  align-items: center;
}

.referredMaindiv {
  flex-basis: 65%;
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.referredAndAddreferenceMinDiv .addreferenceMainDiv {
  flex-basis: 50%;
  width: 100%;
  display: flex;
  justify-content: end;
  padding-bottom: 10px;
}

.referredFirstDiv {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  flex-basis: 40%;
}

.referredFirstDiv p {
  margin-bottom: 0;
  font-weight: 700;
  padding-bottom: 10px;
}

.referredFirstDivIcon {
  padding-left: 40px;
  font-size: 25px;
}

.referredSecoundDiv {
  display: flex;
  flex-basis: 60%;
  justify-content: space-between;
}

.referredSecoundDiv p {
  margin-bottom: 0;
  /* font-weight: 600; */
  color: #959595;
  padding-bottom: 10px;
  padding-left: 7px;
  padding-right: 7px;
}

.referredUserText {
  font-size: 18px;
}
.user-dashboard-table {
  margin-top: 5px;
  height: 100%;
  max-height: 56%;
  overflow: hidden;
  background-color: white;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.user-dashboard-table::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f2f8fe;
}

.user-dashboard-table::-webkit-scrollbar {
  width: 8px;
  background-color: #f2f8fe;
}

.user-dashboard-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #cccccc;
}

.referredUsersTable {
  border-collapse: collapse;
  width: 100%;
  border-top-right-radius: 10px;
  background-color: white;
}

.referredUsersTableThead {
  position: sticky;
  top: 0;
  height: 45px;
  background-color: #f2f8fe;
  color: #9c9c9c;
}

.referredUsersTableFirstTh {
  width: 45%;
  padding-left: 20px;
  background-color: #efefef;
  text-align: start;
  border-top-left-radius: 10px;
  font-weight: 400;
}

.referredUsersTableThirdtTh {
  border-top-right-radius: 10px;
  background-color: #efefef;
  font-weight: 400;
}

.referredUsersTableTbodyFirstTd {
  padding-left: 20px;
  /* cursor: pointer; */
  height: 42px;
}

.referredUsersTableTbodyFirstTdPTag{
  margin-bottom: 0 !important;
  width: max-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 15px;
}


.referredUsersTableSecoundTh {
  width: 37%;
  text-align: start;
  background-color: #efefef;
  font-weight: 400;
}
.referredUsersTableTbody {
  height: 50px;
  width: 100%;
  background-color: white !important;
}

.referredUsersTableTbody tr {
  border-bottom: 1px solid #ebebeb;
  background-color: white;
  font-weight: 500;
  color: #000000;
  font-size: 15px;
  height: 42px;
}

/* .MuiButtonBase-root{
    background-color: white !important;
} */
/* 
.MuiButtonBase-root{
    border: 1px solid black;
} */

.referredPage .MuiPagination-ul li button {
  border: 1px solid black;
  border-radius: 0;
}

.referredPage .MuiPaginationItem-root {
  height: 32px !important;
}

.referredPage
  .css-1pi9rcw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff;
  background-color: #1976d2;
  width: 100%;
  padding: 0 !important;
  border-radius: 0;
  margin: 0;
}

.referredAllP {
  border: 1px solid transparent;
}
.referredAllP1 {
  border: 1px solid transparent;
  border-bottom: 1px solid #17508d;
  color: #17508d !important;
  cursor: pointer;
  font-weight: 600;
}

.referredHiredText {
  border: 1px solid transparent;
}

.referredHiredText1 {
  border: 1px solid transparent;
  border-bottom: 1px solid #17508d;
  color: #17508d !important;
  cursor: pointer;
  font-weight: 600;
}

.referredRejectText {
  border: 1px solid transparent;
}
.referredRejectText1 {
  border: 1px solid transparent;
  border-bottom: 1px solid #17508d;
  color: #17508d !important;
  cursor: pointer;
  font-weight: 600;
}

.referredAllP:hover {
  border-bottom: 1px solid #17508d;
  color: #17508d;
  cursor: pointer;
}
.referredRejectText:hover {
  border-bottom: 1px solid #17508d;
  color: #17508d;
  cursor: pointer;
}

.referredHiredText:hover {
  border-bottom: 1px solid #17508d;
  color: #17508d;
  cursor: pointer;
}

.tableSpiner {
  position: relative;
  left: 29rem;
  top: 7rem;
}
.totalRefEarLoader {
  position: relative;
  bottom: 0.5rem;
}

.amountEarnedTd {
  display: flex;
  align-items: center;
  gap: 30px;
  height: 42px;
  /* justify-content: start; */
}
.statusTd {
  height: 42px;
}

.paidStatus {
  color: #0e9280;
}
.unPaidStatus {
  color: #d5b100;
}

.referred-header-container {
  display: flex;
  justify-content: start;
  width: 100%;
  margin-bottom: 10px;
}

.referred-header-container span {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: end;
}

.referred-header-container span > input {
  width: 75%;
  border: none;
  outline: none;
  height: 40px;
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  padding-left: 30px;
  font-size: 14px;
}

.referred-header-container span > input::placeholder {
  color: #8e8e8e;
  font-size: 14px;
}

.referred-header-container span > button {
  border: none;
  background: white;
  height: 40px;
  width: 60px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  color: #989898;
  outline: none;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}

@media only screen and (min-width: 1370px) {
  .referredUsersMainDiv .referredAndEarningMainDiv {
    height: 25%;
  }

  .user-dashboard-table {
    max-height: 52%;
  }

  .user-dashboard-table tbody tr {
    height: 45px !important;
  }
  .user-dashboard-table tbody tr td{
    height: 45px !important;
  }

  .referredUsersMainDiv .referredAndAddreferenceMinDiv {
    height: 40px;
  }

  .addReferenceMainDivContaine {
    bottom: -10%;
  }
  .referredUsersMainDiv .referredAndEarningMainDiv .totalReferralsDiv {
    height: 130px;
  }

  .referredUsersMainDiv .referredAndEarningMainDiv .earningsDiv {
    height: 130px;
  }
}

@media only screen and (max-width: 1024px) {
  .add-reference-click button {
    height: 30px !important;
    width: 120px !important;
    font-size: 11px !important ;
  }

  .referredUsersMainDiv .referredAndEarningMainDiv .totalReferralsDiv {
    height: 85px;
    width: 180px;
  }

  .referredUsersMainDiv .referredAndEarningMainDiv .earningsDiv {
    height: 85px;
    width: 180px;
  }

  .referredFirstDiv p{
    font-size: 15px;
  }

  .referredSecoundDiv {
    font-size: 12px;
    white-space: nowrap;
  }

  .containeFirstDiv h6{
    font-size: 13px;
  }

  .containethirdDiv p{
    font-size: 13px !important;
  }

  .containeSecoundDiv p{
    font-size: 11px !important;
  }

  .referredUsersTable th{
    font-size: 14px;
  }

  .referredUsersTable tbody tr{
    font-size: 13px;
  }

  .addreferenceMainDiv span input{
    height: 35px;
  }
  .addreferenceMainDiv span button{
    height: 35px;
  }
}

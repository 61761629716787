.mobile-dash{
    background-image: url("../assets/Layer\ 2.png");
    background-size: contain;
    background-repeat: no-repeat;
}

.dash-head{
    margin: 30px 0px;
}

.dash-head p{
    margin-left: 20px;
    font-weight: 600;
}

.dash-head .head-search{
    display: flex;
    justify-content: space-between;
    padding: 0px 4%;
}

.dash-head .add-ref-btn {
  background-color: #0e9280;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 25%;
  border-radius: 5px;
  outline: none;
  border: none;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
}

.candidate-header-container-mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }

.dash-table{
    height: 550px;
}

.dash-table thead{
    background-color: #E2E2E2;
}

.dash-table table tbody{
    
}

.dash-table table tbody tr{
    border:none;
}

.dash-table table tbody td{
    border: none;
    height: 50px;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.foot-nav{
    height: 50px;
    background-color: #265F9D;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.foot-nav span{
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 500;
    border-right: 1px solid white;
}

.foot-nav .inActive{
   font-weight: 300; 
   transition: all 0.5s ease-in;    
}

.foot-nav .active{
    font-weight: 500;
    position: relative;
    transition: all 0.2s ease-in;
}

.foot-nav .active::after{
    content: "";
    width: 32%;
    border: 1px solid white;
    position: absolute;
    /* height: 41px; */
    /* top: -6px; */
    bottom: -5px;
    left: 35%;
    transition: all 0.5s ease-in;
}


.dash-head .req-div .requirement{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dash-head .req-div p{
    text-align: center;
    margin: 0px;
    margin-bottom: 0.5rem;
}

.dash-head .req-div .text{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: self-start;
    text-align: start;
}

.dash-head .req-div span{
    margin-bottom: 0.5rem;
    color: #959595;
}

.dash-head .req-div .requirement .hr{
    width: 80%;
}

.dash-head .hr-div hr{
    width: 80%;
}

.dash-head .req-view{
    padding: 0px 10%;
}

.dash-head .requirement .close-btn{
    position: absolute;
    top: 0;
    right: 1%;
    cursor: pointer;
}
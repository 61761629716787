* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Profile Section CSS */

.profile-main-section {
    background: #F2F8FE;
    height: calc(100vh - 65px);
    padding: 0px 50px 32px 50px;
}

.profile-main-section .ant-btn-default:not(:disabled):hover {
    color: #fff !important;
    border-color: transparent !important;
}

.profile-main-section .profile-left-icon {
    font-size: x-large;
    position: relative;
    bottom: 0px;
    right: 20px;
    cursor: pointer;
    color: #000;
}

.profile-main-section .profile {
    background-color: #fff;
    height: 658px;
    border-radius: 0px 0px 5px 5px;
    padding-bottom: 20px;
}

.profile .react-tabs__tab-list {
    display: flex !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    background: #265F9D !important;
    font: 1em sans-serif;
    color: white;
    height: 80px !important;
    width: 100%;
    border-radius: 5px 5px 0px 0px;
}

.profile .react-tabs__tab--selected {
    background: #fff !important;
    color: black !important;
    border: none !important;
    font-weight: 600;
    width: 200px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px 8px 0 0;
}
/* 
.profile .react-tabs__tab:last-child{
    background: #001E3E !important;
    color: white !important;
    border: none !important;
    list-style: none;
    width: 200px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 5px 0px 0px;
    margin-top: 0;
    margin-right: -21px;
} */

.profile .react-tabs__tab {
    width: 200px;
    margin-top: 5px;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.profile .react-tabs__tab:focus:after {
    background: transparent;
}

.single-input-class .MuiInputBase-input {
    width: 345px !important;
    padding: 12.5px 27px !important;
    height: 25px;
    font-size: 15.5px !important;
}

.single-input-class .MuiFormLabel-root {
    font-size: 15px !important;
    position: absolute !important;
    left: 0;
    top: -2px !important;
}

.single-input-class .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #265F9D;
    border-width: 1px;
    border-radius: 6px;
}

.single-input-class .MuiOutlinedInput-notchedOutline {
    border-radius: 6px;
    position: absolute;
    top: -3px;

}

.single-input-class .MuiInputLabel-shrink {
    background-color: white !important;
    padding-right: 10px !important;
}

.single-address-class .MuiInputBase-input {
    width: 845px !important;
    padding: 12.5px 27px !important;
    height: 25px;
    font-size: 15.5px !important;
}

.single-address-class .MuiFormLabel-root {
    font-size: 14px !important;
    position: absolute !important;
    left: 0;
    top: -2px !important;
}

.single-address-class .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #265F9D;
    border-width: 1px;
}

.single-address-class .MuiOutlinedInput-notchedOutline {
    border-radius: 6px;
    position: absolute;
    top: -3px;
}



.profile .profile-input-group {
    padding-left: 125px;
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 350px !important;
}

.profile .profile-input-group1 {
    padding-left: 125px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 350px !important;
}

.profile .profile-input-group2 {
    padding-left: 125px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 350px !important;
}

.profile .profile-input-group3 {
    padding-left: 125px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 350px !important;
}

.profile .profile-input-group4 {
    padding-left: 125px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 350px !important;
}

.profile .profile-input-group5 {
    padding-left: 125px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 20rem;
}

.profile-main-section .profile .email-main-section {
    padding-left: 120px;
    padding-top: 30px;
}


/* Email Section CSS */

.email-main-section .email-change-group {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
    width: 380px;
    margin-top: 20px;
    font-size: 14px;
}


.email-main-section .current-email-address-p-tag>p {
    margin-top: 20px;
    margin-bottom: 5px;
    word-spacing: 1px;
    font-size: 14px;
}

.email-main-section .current-email-address-p-tag>h4 {
    font-size: 15px;
}

.email-main-section .email-sec-paragraphs {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.email-main-section .change-email-div {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 70px;
}

.email-main-section .email-sec-change-email-but {
    height: 45px;
    width: 210px;
    color: #fff;
    font-size: 14px;
    background: #265F9D;
    text-align: center;
    border: none;
    border-radius: 8px;
    cursor: pointer;

}


.email-main-section .email-sec-change-email-but1 {
    height: 45px;
    width: 210px;
    color: #fff;
    font-size: 16px;
    background: #265F9D;
    text-align: center;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    opacity: .5 !important;

}

.email-main-section .email-and-otp-section {
    display: flex;
    align-items: stretch;
}

.email-main-section .otp-main-section {
    width: 25%;
    margin-left: 15px;
}

.email-main-section .otp-inp-and-conf-btn {
    display: flex;
    align-items: center;
    margin-top: 60px;
}

.email-main-section .otp-confirm-btn {
    height: 45px;
    width: 155px;
    text-align: center;
    color: #fff;
    font-size: 17px;
    letter-spacing: .5px;
    font-weight: 100;
    background: #265F9D;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.email-main-section .enter-otp-input {
    width: 150px;
    padding: 10.5px 10px !important;
    height: 44px;
    border-radius: 8px;
    border: 1px solid #bbb;
    outline: none;
    margin-right: 15px;
}

.email-main-section .enter-otp-input:hover {
    border: 1px solid black;
}

.email-main-section .enter-otp-input:focus {
    border: 1px solid #265F9D;
}

.email-main-section .email-changed-succ-section {
    flex-direction: column;
    padding-left: 25px;
    padding-top: 55px;
}

.succ-check-icon {
    color: #0E9280;
    width: 98px;
    font-size: x-large;
}

/* Password Section CSS */


.profile-main-section .profile .password-main-section {
    padding-left: 125px;
    padding-top: 30px;
    font-size: 14px;
}


.password-main-section .password-section-group {
    padding-top: 25px;
    display: flex;
    width: 30%;
    gap: 20px;
    flex-direction: column;
    font-size: 14px;
}

.password-main-section .password-section-group .update-password-btn {
    height: 45px;
    width: 200px;
    color: #fff;
    font-size: 15px;
    background: #265F9D;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 15px;
    margin-left: 80px;
}

/* Avatar Section CSS */

.profile-main-section .profile .avatar-main-section {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    margin-right: 20rem;
    padding-top: 80px;
}

.profile-main-section .profile .avatar-main-section .MuiTypography-subtitle1 {
    opacity: 0 !important;
}

.profile-main-section .profile .avatar-main-section .MuiDropzoneArea-root {
    width: 30% !important;
    height: 90px !important;
    min-height: 0px !important;
    border: 2px dashed #707070 !important;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    left: 20rem;
    margin-top: 25px;
}

.profile-main-section .profile .avatar-main-section .MuiDropzoneArea-icon {
    opacity: 0;
}

.profile-main-section .profile .avatar-main-section .MuiTypography-h5 {
    font-size: 15px !important;
}

.profile-main-section .profile .avatar-main-section .MuiDropzoneArea-text {
    margin-top: 30px !important;
    margin-bottom: 2px !important;

}

.profile-main-section .profile .avatar-main-section .MuiGrid-spacing-xs-8 {
    width: 7.5%;
    margin: -18px 0px 0px -139px !important;
}

.profile-main-section .profile .avatar-main-section .MuiGrid-spacing-xs-8>.MuiGrid-item {
    padding: 0 !important;
}

.profile-main-section .profile .avatar-main-section .MuiDropzonePreviewList-image {
    border-radius: 100px !important;
    height: 140px !important;
    max-width: none !important;
    width: 140px;
    margin-top: 17px;
}

.profile-main-section .profile .avatar-main-section .profile-avatar-ic {
    height: 130px;
    width: 90px;
    position: absolute;
    left: 8.4rem;
    color: #959595;
}

.profile-main-section .profile .avatar-main-section .profile-avatar-ic-border {
    border-radius: 50%;
    border: 1px solid gray;
    height: 140px;
    width: 140px;
    margin-left: 3.6rem;
}

.avatar-main-section .MuiDropzonePreviewList-removeButton:focus {
    opacity: 1 !important;
}

.profile-main-section .profile .avatar-main-section .MuiDropzonePreviewList-removeButton {
    top: -1px !important;
    right: -140px !important;
    width: 40px;
    height: 40px;
    position: absolute;
    transition: .5s ease;
}

.profile-main-section .profile .avatar-main-section .avatar-notice-head {
    padding-left: 4.5rem;
    position: relative;
    bottom: 15px;
    font-size: 15px;
}

.profile-main-section .profile .update-avatar-btn {
    height: 40px;
    width: 130px;
    color: #fff;
    font-size: 15px;
    background: #265F9D;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 40px;
    margin-left: 65px;
}


.profile-main-section .profile .update-avatar-btn1 {
    height: 40px;
    width: 130px;
    color: #fff;
    font-size: 15px;
    background: #265F9D;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 40px;
    margin-left: 65px;
    opacity: .5 !important;

}


/* Bank Details CSS */
.bank-details-main-section .address-filed {
    flex-basis: 50%;
    flex-direction: column;
    display: flex;
    gap: 40px;
}

.profile-main-section .profile .bank-details-main-section {
    padding-left: 200px;
    padding-right: 100px;
    width: 100%;
    padding-top: 50px;
    display: flex;
    gap: 40px 100px;
    flex-wrap: wrap;
}

.profile-main-section .profile .bank-details-submit-btn {
    height: 45px;
    width: 120px;
    background: #fff;
    border: 1px solid #B7B7B7;
    color: #000;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 24.5rem;
}

.profile-main-section .profile .bank-details-submit-btn:hover {
    color: #fff;
    background: #265F9D;
    transition: .8s all;
}

.profile-main-section .profile .update-profile-btn {
    height: 45px;
    width: 160px;
    color: #fff;
    background: #265F9D;
    border: none;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    left: 27rem;
    bottom: 3.1rem;
}

.bankDetailsSpin {
    position: relative;
    left: 40rem;
    top: 13rem;
}

/* Contact US Section CSS */

.contact-us-main-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
}

.contact-us-main-section .email-and-phone-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    width: 450px;
    height: 150px;
    border-radius: 6px;
}

.contact-us-main-section .contact-black-logo {
    background-color: #000;
    color: #fff;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
}

.contact-us-main-section .email-and-phone-sec .email-para {
    font: normal normal normal 15px/20px Poppins;
    font-weight: 500;
    color: #000000;
}

.contact-us-main-section .phone-para {
    font: normal normal normal 15px/20px Poppins;
    color: #000000;
    font-weight: 500;
}

.email-and-phone-sec .email-section {
    display: flex;
    margin-top: 12px;
}

.email-and-phone-sec .number-section {
    display: flex;
    margin-top: 15px;
}

.contact-us-main-section .header-text-in-contact-sec {
    font: normal normal normal 15px/20px Poppins;
    color: #000000;
    font-weight: 500;
    padding-bottom: 30px;
    padding-top: 20px;

}


/* Laptop Design */

@media screen and (max-height: 760px) {
    .profile-main-section .profile {
        overflow-y: scroll;
    }

    .profile-main-section .profile::-webkit-scrollbar {
        width: 10px;
    }

    /* Handle */
    .profile-main-section .profile::-webkit-scrollbar-thumb {
        background: #bbb;
        border-radius: 10px;
    }

    /* Handle on hover */
    .profile-main-section .profile::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .profile-main-section .profile ul {
        position: sticky;
        top: 0px;
        z-index: 4;
    }
}
.referrer-left-header-Export button {
  height: 30px;
  width: 100px;
  background-color: white;
  border: 1px solid darkgray;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
}
.referrer-left-header-Export button:hover {
  color: white;
  background-color: #0e9280;
  transition: 0.5s all;
}

.exportAllText{
  opacity: 0.5;
}

.export-header {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #265f9d;
  font-size: 17px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.referrer-export-show-modal .ant-modal-close {
  position: absolute;
  top: -25px;
  right: -30px;
  border: 3px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  display: none !important;
}

.referrer-export-show-modal .ant-modal-close span {
  font-weight: 600;
  line-height: 0px;
  font-size: 16px;
}

.referrer-export-show-modal:where(
    .css-dev-only-do-not-override-1e3x2xa
  ).ant-modal
  .ant-modal-close:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.06);
  text-decoration: none;
}

.referrer-export-all-section {
  /* padding-left: 20px;
    padding-right: 20px; */
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
  height: 302px !important;
}
.referrer-ExportAll {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.referrer-ExportAll label {
  font-size: 17px;
  cursor: pointer;
}
.referrer-ExportAll input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

/* .referrer-custom input[type=text]{
    width: 50px;
    height: 32px;
    padding-left: 5px;
    outline: none;

  }

  .referrer-custom label{
      font-size: 20px;
  }

  .referrer-custom{
    display: flex;
    align-items: center;
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
  } */

.referrer-export-all-section .referrer-custom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.referrer-export-all-section .referrer-custom1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
}
.customInput {
  opacity: 0.3 !important;
}

.referrer-export-all-section input[type="text"] {
  box-sizing: border-box;
  width: 28%;
  height: calc(3em + 2px);
  margin: 0 0 1em;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 0.5em;
  background: #fff;
  resize: none;
  outline: none;
}
.referrer-export-all-section input[type="text"][required]:focus {
  border-color: #265f9d;
}
.referrer-export-all-section
  input[type="text"][required]:focus
  + label[placeholder]:before {
  color: #265f9d;
}
.referrer-export-all-section
  input[type="text"][required]:focus
  + label[placeholder]:before,
.referrer-export-all-section
  input[type="text"][required]:valid
  + label[placeholder]:before {
  transition-duration: 0.2s;
  transform: translate(0, -1.5em) scale(0.9, 0.9);
}
.referrer-export-all-section
  input[type="text"][required]:invalid
  + label[placeholder][alt]:before {
  content: attr(alt);
}
.referrer-export-all-section input[type="text"][required] + label[placeholder] {
  display: block;
  pointer-events: none;
  line-height: 1.25em;
  margin-top: calc(-3em - 2px);
  margin-bottom: calc((3em - 1em) + 2px);
}
.referrer-export-all-section
  input[type="text"][required]
  + label[placeholder]:before {
  content: attr(placeholder);
  display: inline-block;
  margin: 0 calc(1em + 2px);
  padding: 0 2px;
  color: #898989;
  white-space: nowrap;
  transition: 0.3s ease-in-out;
  background-image: linear-gradient(to bottom, #fff, #fff);
  background-size: 100% 5px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
}

.referrer-export-all-section .or {
  width: 100%;
  text-align: center;
  position: relative;
}

.referrer-export-all-section .or .lr::before {
  content: "";
  position: absolute;
  width: 20%;
  top: 50%;
  right: 55%;
  border: 0.1px solid #cdcdcdb8;
}

.referrer-export-all-section .or .lr::after {
  content: "";
  position: absolute;
  width: 20%;
  top: 50%;
  left: 55%;
  border: 0.1px solid #cdcdcdb8;
}

.referrer-pop-submit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.referrer-pop-submit button{
  width: 150px;
  height: 40px;
  margin-top: 10px;
  border-radius: 5px;
  background-color: white;
  color: black;
  border: 1px solid darkgray;
}

.referrer-pop-submit button:hover {
  background-color: #265f9d;
  color: white !important;
  transition: 0.5s all;
  border: 1px solid white !important;
}

.exportPopCancel{
  position: absolute;
  font-size: 25px !important;
  color: white !important;
  right: -2.5rem;
  top: -2.5rem;
}

.ant-modal-content {
  padding: 0px !important;
}

.add-user-click-btn button {
  height: 40px;
  width: 150px;
  border-radius: 5px;
  background-color: white;
  border: none;
  font-size: 14px;
  outline: none;
}

.add-user-click-btn button:hover {
  color: white;
  background-color: #265f9d;
}

.user-modal-pop .ant-modal .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0;
}

.user-modal-pop .ant-modal-root .ant-modal-mask {
  position: fixed;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 75%);
}

.user-modal-pop .ant-modal-close {
  position: absolute;
  top: -15px;
  right: -35px;
  border: 3px solid white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-modal-pop .ant-modal-close span {
  font-weight: bold;
  line-height: 0px;
  font-size: 16px;
}

.user-modal-pop .ant-modal-close:hover {
  color: white !important;
  background-color: rgb(0 0 0 / 0%);
  text-decoration: none;
}

.add-user-all-details .user-header-section {
  height: 55px;
  width: 100%;
  background-color: lightgray;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-user-all-details .user-header-section p {
  font-size: 14px;
  font-weight: 700;
  margin: 0px;
}

.user-text-input-section .css-11gx8lr > :not(style) {
  margin: 0;
  width: 400px;
}

.user-text-input-section .user-add-required-all-details label {
  position: absolute;
  left: 10px;
  top: -5px;
}

.user-text-input-section .edit-required-all-sec label {
  position: absolute;
  left: 10px;
  top: -5px;
}

.user-text-input-section .edit-required-all-sec div:nth-child(2) input {
  /* color: rgba(0, 0, 0, 0.6); */
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4375em;
  padding: 0px;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 10px;
  top: -5px;
  transform: translate(14px, 16px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}

.user-text-input-section .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 10px;
  top: -5px;
}

.user-text-input-section .MuiOutlinedInput-root {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  height: 45px;
  display: inline-flex;
  align-items: center;
  position: relative;
  border-radius: 5px !important;
}

.user-text-input-section .MuiSelect-select {
  user-select: none;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  border: 0;
  box-sizing: content-box;
  background: none;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  padding: 11.5px 14px !important;
}

.user-text-input-section .MuiSelect-root {
  border-radius: 4px;
}

.add-user-all-details .add-user-submit-btn {
  display: flex;
  justify-content: center;
  position: relative;
  top: 40px;
}

.add-user-all-details .add-user-submit-btn button {
  height: 50px;
  width: 120px;
  border: 1px solid lightgray;
  border-radius: 5px;
  background-color: white;
  font-size: 18px;
}

.add-user-all-details .add-user-submit-btn button:hover {
  color: white;
  background-color: #265f9d;
}

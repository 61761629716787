.action-div{
    display: flex;
    justify-content: space-around;
}

.action-div span{
    /* font-family: "Helvetica"; */
    font-weight: 500;
    cursor: pointer;
}

.admin-user-referral {
    display: flex;
    align-items: center;
    gap: 10px;
}
.admin-user-referral >p {
    margin: 0;
    padding: 0;
}

.referrer-main-section {
    width: 98vw;
    height: 100%;
    min-height: calc(100vh - 65px); 
    display: flex;
}

.referrer-main-section .referrer-left-side-sec {
    width: 30%;
 

    /* border: 1px solid lightgray; */
}

.referrer-main-section .referrer-right-side-sec{
    width: 70%;
    /* border: 1px solid lightgray; */
    background-color: #EFF5FB;
}
.profile-password-click {
  width: 100%;
  padding-top: 5px;
}

.profile-password-click button {
  height: 30px;
  width: 140px;
  border: 1px solid lightgray;
  border-radius: 15px;
  font-size: 11px;
  background-color: #265f9d;
  color: white;
  border: none;
  padding-top: 2px;
}

.password-change-modal:where(.css-dev-only-do-not-override-1e3x2xa).ant-modal
  .ant-modal-content {
  position: relative;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0px !important;
}

.password-change-modal:where(.css-dev-only-do-not-override-1vtf12y).ant-modal-root .ant-modal-mask {
    position: fixed;
    top: 0;
    inset-inline-end: 0;
    bottom: 0;
    inset-inline-start: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgb(0 0 0 / 75%);
}


.password-change-modal .ant-modal-close {
  position: absolute;
  top: -15px;
  right: -35px;
  border: 3px solid white;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  font-size: 12px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.password-change-modal .ant-modal-close span {
  font-weight: bold;
  line-height: 0px;
  font-size: 15px;
}

.password-change-modal:where(.css-dev-only-do-not-override-1vtf12y).ant-modal
  .ant-modal-close:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.06);
  text-decoration: none;
}

.change-pass-all-details .change-pass-header {
  height: 55px;
  width: 100%;
  background-color: lightgray;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change-pass-all-details .change-pass-header p {
  font-size: 14px;
  font-weight: 700;
  margin: 0px;
}

.pass-current-new-confirm .pass-check-section {
  margin: 0;
  margin-left: 60px;
  /* margin-top: 20px; */
  padding-top: 60px;
}

.pass-check-section .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 15px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 20px;
  top: -5px;
  transform: translate(14px, 16px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}

.pass-check-section .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  height: 45px;
  border-radius: 8px;
}

.change-pass-all-details .pass-update-btn {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.change-pass-all-details .pass-update-btn button {
  height: 40px;
  width: 200px;
  border: 2px solid #265F9D;
  border-radius: 5px;
  font-size: 15px;
  background-color: white;
  color: #265F9D;
  transition: .5s;
  
  /* border: none; */
}
.change-pass-all-details .pass-update-btn button:hover{
  background-color: #265f9d;
  color: #ffffff;
  border: 2px solid #265f9d ;
}

.password-change-modal .ant-modal-close {
  display: none;
}
.password-change-modal .pop-up-closed{
  position: absolute;
  top: -1.2rem;
  right: -2.0rem;
  font-size: 28px;
  color: #ffffff;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 425px) {
    .mobile-sign-main-container .be-wise-earn-with {
        text-align: center;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
    .mobile-sign-main-container{
      background-image: url("../assets/Layer\ 2.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
        padding: 5%;
    }

  .mobile-sign-main-container header {
    padding: 2.5% 5%;
  }
  .mobile-sign-main-container .be-wise-earn-with {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 50px;
    height: max-content;
    border-radius: 10px;
    transition: all 1s ease-in;
  }
  .mobile-sign-main-container .be-wise-earn-with p {
    margin: 0;
    padding: 2.5% 5%;
    font-size: 30px;
    color: #265f9d;
  }

  .mobile-sign-main-container .form-holder .MuiBox-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .mobile-sign-main-container .form-holder p {
    margin: 0;
    font-size: 30px;
    color: #265f9d;
  }

  .mobile-sign-main-container
    .form-holder
    .MuiInputBase-root {
    border-radius: 10px;
  }

  .mobile-sign-main-container .form-holder input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .mobile-sign-main-container .form-holder .usaflag-or-usernumber {
    width: 87%;
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    height: 40px;
    border-radius: 10px;
  }

  .mobile-sign-main-container .form-holder .usaflag-or-usernumber .giusaflag {
    width:40px;
    padding: 5px;
    border-right: 1px solid lightgray;
  }

  .mobile-sign-main-container .form-holder .usaflag-or-usernumber .phone-input {
    border: none;
    padding: 0 19px;
    outline: none;
    background-color: transparent;
    width: 100%;
  }

  .mobile-sign-main-container .form-holder .usaflag-or-usernumber .phone-input::placeholder {
    font-size: 15px;
    color: #C5C5C5;
   }


  .mobile-sign-main-container .form-holder button {
    width: 65%;
    height: 50px;
    border-radius: 10px;
    border: none;
    background: #265f9d 0% 0% no-repeat padding-box;
    color: white;
    font-size: 18px;
    outline: none;
  }

  .mobile-sign-main-container
    .form-holder
    .MuiInputBase-input {
    height: 0.8em !important;
    padding: 13px !important;
  }

  .mobile-sign-main-container
    .form-holder
    .MuiInputLabel-shrink {
    top: 0px !important;
  }

  .mobile-sign-main-container
    .form-holder
    .MuiFormLabel-root {
    top: -8px;
  }

  .mobile-sign-main-container
    .form-holder
    .MuiInputLabel-shrink {
    top: 0!important;
  }

  .mobile-sign-main-container
    .form-holder
    .MuiSelect-select  {
    border-radius: 10px !important;
    min-height: auto;
  }

  .mobile-sign-main-container .mobile-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
  }

  .mobile-sign-main-container .mobile-preview .already-account {
      margin-bottom: 2%;
      margin-top: 1%;
      color: #989898;
    }
    .mobile-sign-main-container .mobile-preview .forgot-your-password {
        margin-top: 1%;
        margin-bottom: 2%;
        color: #989898;
    }
    .mobile-sign-main-container .mobile-preview .do-not-have-an-account {
        margin-top: 1%;
        margin-bottom: 2%;
        color: #989898;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}
